@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'JungleMoney';
  src: url('./fonts/JUNGLEMONEY.otf') format('opentype'); /* Correcta ruta relativa */
  font-weight: normal;
  font-style: normal;
}

body {
  @apply bg-[#f1f2ec];
}

html, body {
  font-family: 'JungleMoney', sans-serif; /* Aplica la fuente a todo el documento */
}
